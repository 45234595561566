import React from 'react';
import logo from './logo.svg';
import phone from './resources/images/xcene-phone-header.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="xcene-logo" alt="logo" />
        <img src={phone} className="cover-phone" alt="download the app"/>
      </header>
    </div>
  );
}

export default App;
